import React from 'react'

const BannerContext = React.createContext()
BannerContext.displayName = 'BannerContext'

function BannerProvider(props) {
  const [showATABanner, setShowATABanner] = React.useState(true)

  const value = { showATABanner, setShowATABanner }

  return <BannerContext.Provider value={value} {...props} />
}

function useATABanner() {
  const context = React.useContext(BannerContext)
  if (context === undefined) {
    throw new Error(`useATABanner must be used within a BannerProvider`)
  }
  return context
}

export { BannerProvider, useATABanner }
