import React from 'react'

import { LocationProvider } from './location-context'
import { BannerProvider } from './banner-context'
import { SearchProvider } from './search-context'

function AppProviders(props) {
  return (
    <>
      <SearchProvider>
        <BannerProvider>
          <LocationProvider>{props.children}</LocationProvider>
        </BannerProvider>
      </SearchProvider>
    </>
  )
}

export default AppProviders
