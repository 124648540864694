import React from 'react'

const LocationContext = React.createContext()
LocationContext.displayName = 'LocationContext'

function LocationProvider(props) {
  const [ataLocation, setATALocation] = React.useState('ALL')

  const value = { ataLocation, setATALocation }

  return <LocationContext.Provider value={value} {...props} />
}

function useATALocation() {
  const context = React.useContext(LocationContext)
  if (context === undefined) {
    throw new Error(`useATALocation must be used within a LocationProvider`)
  }
  return context
}

export { LocationProvider, useATALocation }
